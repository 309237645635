var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("e-sidebar", {
    attrs: {
      title: _vm.isEdit
        ? _vm.$t("Editar Forma de Pagamento")
        : _vm.$t("Nova Forma de Pagamento"),
      show: _vm.showSidebar,
      fetching: _vm.loading,
      saving: _vm.saving,
      width: "600px",
    },
    on: {
      save: _vm.savePaymentType,
      hidden: function ($event) {
        _vm.showSidebar = false
      },
    },
    scopedSlots: _vm._u([
      {
        key: "content",
        fn: function () {
          return [
            _c(
              "FormulateForm",
              { ref: "formPaymentType", attrs: { name: "formPaymentType" } },
              [
                _c(
                  "b-row",
                  [
                    _c(
                      "b-col",
                      [
                        _c("FormulateInput", {
                          staticClass: "required",
                          attrs: {
                            id: "payment-type-name",
                            label: _vm.$t("Nome"),
                            type: "text",
                            validation: "required",
                          },
                          model: {
                            value: _vm.formPaymentType.name,
                            callback: function ($$v) {
                              _vm.$set(_vm.formPaymentType, "name", $$v)
                            },
                            expression: "formPaymentType.name",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "b-col",
                      { attrs: { md: "3" } },
                      [
                        _c("FormulateInput", {
                          attrs: {
                            id: "payment-type-grace-period",
                            label: _vm.$t("Carência"),
                            instruction: _vm.$t(
                              "Período de carência são os dias entre a venda e o recebimento do dinheiro."
                            ),
                            type: "number",
                          },
                          model: {
                            value: _vm.formPaymentType.gracePeriod,
                            callback: function ($$v) {
                              _vm.$set(_vm.formPaymentType, "gracePeriod", $$v)
                            },
                            expression: "formPaymentType.gracePeriod",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "b-col",
                      { attrs: { md: "12" } },
                      [
                        _c("FormulateInput", {
                          attrs: {
                            id: "payment-type-description",
                            label: _vm.$t("Descrição"),
                            type: "textarea",
                          },
                          model: {
                            value: _vm.formPaymentType.description,
                            callback: function ($$v) {
                              _vm.$set(_vm.formPaymentType, "description", $$v)
                            },
                            expression: "formPaymentType.description",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "b-card-actions",
                  {
                    attrs: {
                      "action-collapse": "",
                      title: _vm.$t("Parâmetros"),
                    },
                  },
                  [
                    _c(
                      "b-row",
                      [
                        _c("b-col", { attrs: { md: "12" } }, [
                          _c("p", { staticClass: "h4" }, [
                            _vm._v(" " + _vm._s(_vm.$t("Geral")) + " "),
                          ]),
                        ]),
                        _c(
                          "b-col",
                          { attrs: { md: "4" } },
                          [
                            _c("FormulateInput", {
                              attrs: {
                                id: "payment-type-not-generate-accounts-payable",
                                label: _vm.$t("Não gerar Contas a pagar"),
                                instruction: _vm.$t(
                                  "Quando selecionado, pagamentos que utilizam esta forma de pagamento não irão gerar um Contas a Pagar"
                                ),
                                type: "switch",
                              },
                              model: {
                                value:
                                  _vm.formPaymentType
                                    .notGenerateAccountsPayable,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.formPaymentType,
                                    "notGenerateAccountsPayable",
                                    $$v
                                  )
                                },
                                expression:
                                  "formPaymentType.notGenerateAccountsPayable",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "b-col",
                          { attrs: { md: "4" } },
                          [
                            _c("FormulateInput", {
                              attrs: {
                                id: "payment-type-not-generate-receivable",
                                label: _vm.$t("Não gerar Recebíveis"),
                                instruction: _vm.$t(
                                  "Quando selecionado, recebimentos que utilizam esta forma de pagamento não irão gerar um Recebível"
                                ),
                                type: "switch",
                              },
                              model: {
                                value:
                                  _vm.formPaymentType.notGenerateReceivable,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.formPaymentType,
                                    "notGenerateReceivable",
                                    $$v
                                  )
                                },
                                expression:
                                  "formPaymentType.notGenerateReceivable",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "b-col",
                          { attrs: { md: "4" } },
                          [
                            _c("FormulateInput", {
                              attrs: {
                                id: "payment-type-allow_receivable_partial_write_off",
                                label: _vm.$t("Permite baixa parcial?"),
                                instruction: _vm.$t(
                                  "Quando selecionado, recebimentos que utilizam esta forma de pagamento poderão realizar baixa parcial"
                                ),
                                type: "switch",
                              },
                              model: {
                                value:
                                  _vm.formPaymentType
                                    .allowReceivablePartialWriteOff,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.formPaymentType,
                                    "allowReceivablePartialWriteOff",
                                    $$v
                                  )
                                },
                                expression:
                                  "formPaymentType.allowReceivablePartialWriteOff",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "b-col",
                          { attrs: { md: "4" } },
                          [
                            _c("FormulateInput", {
                              attrs: {
                                id: "payment-type-is-payroll-deduction",
                                label: _vm.$t("Usado para desconto em folha?"),
                                instruction: _vm.$t(
                                  "Quando selecionado, esta forma de pagamento será usada para descontos em folha"
                                ),
                                type: "switch",
                              },
                              model: {
                                value: _vm.formPaymentType.isPayrollDeduction,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.formPaymentType,
                                    "isPayrollDeduction",
                                    $$v
                                  )
                                },
                                expression:
                                  "formPaymentType.isPayrollDeduction",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "b-col",
                          { attrs: { md: "4" } },
                          [
                            _c("FormulateInput", {
                              attrs: {
                                id: "payment-type-is-chopp-gift-card",
                                label: _vm.$t("Cartão de presente de chope?"),
                                instruction: _vm.$t(
                                  "Quando selecionado, esta forma de pagamento será usada na conversão de Cartão de Presente de chope"
                                ),
                                type: "switch",
                              },
                              model: {
                                value: _vm.formPaymentType.isChoppGiftCard,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.formPaymentType,
                                    "isChoppGiftCard",
                                    $$v
                                  )
                                },
                                expression: "formPaymentType.isChoppGiftCard",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "b-col",
                          { attrs: { md: "4" } },
                          [
                            _c("FormulateInput", {
                              attrs: {
                                id: "payment-type-is-automatic-debit",
                                label: _vm.$t("Débito automático?"),
                                instruction: _vm.$t(
                                  "Quando selecionado, esta forma de pagamento será usada para em contas a pagar criadas a partir de taxas da conciliação da getnet"
                                ),
                                type: "switch",
                              },
                              model: {
                                value: _vm.formPaymentType.isAutomaticDebit,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.formPaymentType,
                                    "isAutomaticDebit",
                                    $$v
                                  )
                                },
                                expression: "formPaymentType.isAutomaticDebit",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "b-row",
                      { staticClass: "mt-1" },
                      [
                        _c("b-col", { attrs: { md: "12" } }, [
                          _c("p", { staticClass: "h4" }, [
                            _vm._v(" " + _vm._s(_vm.$t("PDV")) + " "),
                          ]),
                        ]),
                        _c(
                          "b-col",
                          { attrs: { md: "4" } },
                          [
                            _c("FormulateInput", {
                              attrs: {
                                id: "payment-type-active-on-pay-box",
                                label: _vm.$t("Utiliza no PDV?"),
                                type: "switch",
                              },
                              model: {
                                value: _vm.formPaymentType.activeOnPayBox,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.formPaymentType,
                                    "activeOnPayBox",
                                    $$v
                                  )
                                },
                                expression: "formPaymentType.activeOnPayBox",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "b-col",
                          { attrs: { md: "4" } },
                          [
                            _c("FormulateInput", {
                              attrs: {
                                id: "payment-type-active-on-pay-box-accouts-payable",
                                label: _vm.$t(
                                  "Utiliza no contas a pagar do PDV?"
                                ),
                                instruction: _vm.$t(
                                  "Quando selecionado, mostra esta forma de pagamento como opção na criação de contas a pagar no PDV"
                                ),
                                type: "switch",
                              },
                              model: {
                                value:
                                  _vm.formPaymentType
                                    .activeOnPayBoxAccountsPayable,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.formPaymentType,
                                    "activeOnPayBoxAccountsPayable",
                                    $$v
                                  )
                                },
                                expression:
                                  "formPaymentType.activeOnPayBoxAccountsPayable",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "b-col",
                          { attrs: { md: "4" } },
                          [
                            _c("FormulateInput", {
                              attrs: {
                                id: "payment-type-active-on-pay-box-return",
                                label: _vm.$t("Utiliza na devolução do PDV?"),
                                instruction: _vm.$t(
                                  "Quando selecionado, mostra esta forma de pagamento como opção na devolução de produtos no PDV"
                                ),
                                type: "switch",
                              },
                              model: {
                                value: _vm.formPaymentType.activeOnPayBoxReturn,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.formPaymentType,
                                    "activeOnPayBoxReturn",
                                    $$v
                                  )
                                },
                                expression:
                                  "formPaymentType.activeOnPayBoxReturn",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "b-row",
                      { staticClass: "mt-1" },
                      [
                        _c("b-col", { attrs: { md: "12" } }, [
                          _c("p", { staticClass: "h4" }, [
                            _vm._v(" " + _vm._s(_vm.$t("ATM")) + " "),
                          ]),
                        ]),
                        _c(
                          "b-col",
                          { attrs: { md: "4" } },
                          [
                            _c("FormulateInput", {
                              attrs: {
                                id: "payment-type-active-on-atm",
                                label: _vm.$t("Utiliza no ATM?"),
                                instruction: _vm.$t(
                                  "Quando selecionado, mostra esta forma de pagamento como opção no ATM"
                                ),
                                type: "switch",
                              },
                              model: {
                                value: _vm.formPaymentType.activeOnAtm,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.formPaymentType,
                                    "activeOnAtm",
                                    $$v
                                  )
                                },
                                expression: "formPaymentType.activeOnAtm",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "b-row",
                      { staticClass: "mt-1" },
                      [
                        _c("b-col", { attrs: { md: "12" } }, [
                          _c("p", { staticClass: "h4" }, [
                            _vm._v(" " + _vm._s(_vm.$t("Nota Fiscal")) + " "),
                          ]),
                        ]),
                        _c(
                          "b-col",
                          { attrs: { md: "4" } },
                          [
                            _c("FormulateInput", {
                              attrs: {
                                id: "payment_type-active_on_invoice_sale",
                                label: _vm.$t("Utiliza na NF do tipo Venda?"),
                                instruction: _vm.$t(
                                  "Quando selecionado, mostra esta forma de pagamento como opção nos meios de pagamento da nota fiscal"
                                ),
                                type: "switch",
                              },
                              model: {
                                value:
                                  _vm.formPaymentType
                                    .activeOnInvoiceCategorySale,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.formPaymentType,
                                    "activeOnInvoiceCategorySale",
                                    $$v
                                  )
                                },
                                expression:
                                  "formPaymentType.activeOnInvoiceCategorySale",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "b-col",
                          { attrs: { md: "4" } },
                          [
                            _c("FormulateInput", {
                              attrs: {
                                id: "payment_type-active_on_invoice_return",
                                label: _vm.$t(
                                  "Utiliza na NF do tipo  Devolução?"
                                ),
                                instruction: _vm.$t(
                                  "Quando selecionado, mostra esta forma de pagamento como opção nos meios de pagamento da nota fiscal"
                                ),
                                type: "switch",
                              },
                              model: {
                                value:
                                  _vm.formPaymentType
                                    .activeOnInvoiceCategoryReturn,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.formPaymentType,
                                    "activeOnInvoiceCategoryReturn",
                                    $$v
                                  )
                                },
                                expression:
                                  "formPaymentType.activeOnInvoiceCategoryReturn",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "b-col",
                          { attrs: { md: "4" } },
                          [
                            _c("FormulateInput", {
                              attrs: {
                                id: "payment_type-active_on_invoice_transference",
                                label: _vm.$t(
                                  "Utiliza na NF do tipo Tranferência?"
                                ),
                                instruction: _vm.$t(
                                  "Quando selecionado, mostra esta forma de pagamento como opção nos meios de pagamento da nota fiscal"
                                ),
                                type: "switch",
                              },
                              model: {
                                value:
                                  _vm.formPaymentType
                                    .activeOnInvoiceCategoryTransference,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.formPaymentType,
                                    "activeOnInvoiceCategoryTransference",
                                    $$v
                                  )
                                },
                                expression:
                                  "formPaymentType.activeOnInvoiceCategoryTransference",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "b-col",
                          { attrs: { md: "4" } },
                          [
                            _c("FormulateInput", {
                              attrs: {
                                id: "payment_type-active_on_invoice_stock_losses",
                                label: _vm.$t(
                                  "Utiliza na NF do tipo Baixa de estoque?"
                                ),
                                instruction: _vm.$t(
                                  "Quando selecionado, mostra esta forma de pagamento como opção nos meios de pagamento da nota fiscal"
                                ),
                                type: "switch",
                              },
                              model: {
                                value:
                                  _vm.formPaymentType
                                    .activeOnInvoiceCategoryStockLosses,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.formPaymentType,
                                    "activeOnInvoiceCategoryStockLosses",
                                    $$v
                                  )
                                },
                                expression:
                                  "formPaymentType.activeOnInvoiceCategoryStockLosses",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "b-col",
                          { attrs: { md: "4" } },
                          [
                            _c("FormulateInput", {
                              attrs: {
                                id: "payment_type-active_on_invoice_product_bonus",
                                label: _vm.$t(
                                  "Utiliza na NF do tipo Bonificação?"
                                ),
                                instruction: _vm.$t(
                                  "Quando selecionado, mostra esta forma de pagamento como opção nos meios de pagamento da nota fiscal"
                                ),
                                type: "switch",
                              },
                              model: {
                                value:
                                  _vm.formPaymentType
                                    .activeOnInvoiceCategoryProductBonus,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.formPaymentType,
                                    "activeOnInvoiceCategoryProductBonus",
                                    $$v
                                  )
                                },
                                expression:
                                  "formPaymentType.activeOnInvoiceCategoryProductBonus",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "b-col",
                          { attrs: { md: "4" } },
                          [
                            _c("FormulateInput", {
                              attrs: {
                                id: "payment_type-active_on_invoice_store_lending",
                                label: _vm.$t(
                                  "Utiliza na NF do tipo Comodato?"
                                ),
                                instruction: _vm.$t(
                                  "Quando selecionado, mostra esta forma de pagamento como opção nos meios de pagamento da nota fiscal"
                                ),
                                type: "switch",
                              },
                              model: {
                                value:
                                  _vm.formPaymentType
                                    .activeOnInvoiceCategoryStoreLending,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.formPaymentType,
                                    "activeOnInvoiceCategoryStoreLending",
                                    $$v
                                  )
                                },
                                expression:
                                  "formPaymentType.activeOnInvoiceCategoryStoreLending",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }