<template>
  <section>
    <e-filters
      :searching="fetching"
      @search="filterData"
      @reset="resetFiltersLocal"
    >
      <div>
        <b-row>
          <b-col md="4">
            <FormulateInput
              id="name"
              v-model="filters.name"
              :label="$t('Nome')"
              :placeholder="$t('Nome')"
            />
          </b-col>
          <b-col md="3">
            <FormulateInput
              id="status"
              v-model="filters.status"
              type="vue-select"
              :label="$t('Status')"
              :placeholder="$t('Todos')"
              :options="activeInactiveTypes()"
            />
          </b-col>
        </b-row>
      </div>
    </e-filters>

    <b-card-actions
      action-refresh
      :show-loading="fetching"
      @refresh="filterData"
    >
      <div slot="title">
        <e-page-size-selector
          :per-page="paging.pageSize"
          @change="pageSizeChange"
        />
      </div>
      <b-table
        ref="checking-account-table"
        show-empty
        responsive
        striped
        class="bordered"
        :empty-text="getEmptyTableMessage($tc('PAYMENT_METHOD.NAME'), 'female')"
        :fields="fields"
        :items="paymentTypes"
        no-local-sorting
        :sort-by.sync="sorting.sortBy"
        :sort-desc.sync="sorting.sortDesc"
        @sort-changed="getData"
      >
        <template #cell(active)="row">
          <e-status-badge :status="row.item.active" />
        </template>

        <template #cell(activeOnPayBox)="row">
          <e-status-badge
            :status="row.item.activeOnPayBox"
            type="yesNo"
          />
        </template>

        <template #cell(activeOnPayBoxAccountsPayable)="row">
          <e-status-badge
            :status="row.item.activeOnPayBoxAccountsPayable"
            type="yesNo"
          />
        </template>

        <template #cell(activeOnPayBoxReturn)="row">
          <e-status-badge
            :status="row.item.activeOnPayBoxReturn"
            type="yesNo"
          />
        </template>

        <template #cell(generateCheckingAccountEntry)="row">
          <e-status-badge
            :status="row.item.generateCheckingAccountEntry"
            type="yesNo"
          />
        </template>

        <template #cell(activeOnInvoiceCategorySale)="row">
          <e-status-badge
            :status="row.item.activeOnInvoiceCategorySale"
            type="yesNo"
          />
        </template>
        <template #cell(activeOnInvoiceCategoryReturn)="row">
          <e-status-badge
            :status="row.item.activeOnInvoiceCategoryReturn"
            type="yesNo"
          />
        </template>
        <template #cell(activeOnInvoiceCategoryTransference)="row">
          <e-status-badge
            :status="row.item.activeOnInvoiceCategoryTransference"
            type="yesNo"
          />
        </template>
        <template #cell(activeOnInvoiceCategoryStockLosses)="row">
          <e-status-badge
            :status="row.item.activeOnInvoiceCategoryStockLosses"
            type="yesNo"
          />
        </template>
        <template #cell(activeOnInvoiceCategoryProductBonus)="row">
          <e-status-badge
            :status="row.item.activeOnInvoiceCategoryProductBonus"
            type="yesNo"
          />
        </template>
        <template #cell(activeOnInvoiceCategoryStoreLending)="row">
          <e-status-badge
            :status="row.item.activeOnInvoiceCategoryStoreLending"
            type="yesNo"
          />
        </template>

        <template #cell(action)="row">
          <e-grid-actions
            :is-active="row.item.active"
            :show-update="$can('Update', 'PaymentMethod')"
            :show-delete="$can('Delete', 'PaymentMethod')"
            :show-activate="$can('Activate', 'PaymentMethod')"
            :show-deactivate="$can('Deactivate', 'PaymentMethod')"
            @update="updatePayment(row)"
            @delete="deletePayment(row.item)"
            @activate="activateDeactivatePayment(row)"
            @deactivate="activateDeactivatePayment(row)"
          />
        </template>
      </b-table>
      <b-row>
        <b-col cols="6">
          <e-pagination-summary
            :current-page="paging.currentPage"
            :per-page="paging.pageSize"
            :total="paging.rowCount"
            :total-on-page="paging.rowsInCurrentPage"
          />
        </b-col>
        <b-col cols="6">
          <b-pagination
            v-model="paging.currentPage"
            align="right"
            :total-rows="paging.rowCount"
            :per-page="paging.pageSize"
            aria-controls="checking-account-table"
            @change="pageChange"
          />
        </b-col>
      </b-row>
    </b-card-actions>

    <payment-type-sidebar
      ref="paymentTypeSidebar"
      @after-save="getData"
    />

    <fab
      v-if="$can('Create', 'PaymentMethod')"
      :main-tooltip="$t('Adicionar conta corrente')"
      @click="createPayment"
    />
  </section>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { BRow, BCol, BPagination, BTable } from 'bootstrap-vue'
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import { EPageSizeSelector, EPaginationSummary } from '@/views/components'
import fab from '@/views/components/FAB.vue'
import { statusTypes } from '@/mixins'
import EStatusBadge from '@/views/components/EStatusBadge.vue'
import EFilters from '@/views/components/EFilters.vue'
import EGridActions from '@/views/components/EGridActions.vue'
import PaymentTypeSidebar from './PaymentTypeSidebar.vue'

export default {
  components: {
    BRow,
    BCol,
    BCardActions,
    BPagination,
    BTable,
    EPaginationSummary,
    EPageSizeSelector,
    fab,
    PaymentTypeSidebar,
    EStatusBadge,
    EFilters,
    EGridActions,
  },

  mixins: [statusTypes],

  data() {
    return {
      fetching: false,
    }
  },

  computed: {
    ...mapState('pages/settings/financial/paymentType', {
      paymentTypes: 'paymentTypes',
      paging: 'paging',
      sorting: 'sorting',
      filters: 'filters',
    }),
    fields() {
      return [
        {
          label: this.$t('Ações'),
          key: 'action',
          class: 'text-center',
          thStyle: { width: '100px' },
        },
        {
          label: this.$t('Nome'),
          key: 'name',
          class: 'text-left',
          sortable: true,
        },
        {
          label: this.$t('Carência (dias)'),
          key: 'gracePeriod',
          class: 'text-center',
          thStyle: { width: '100px' },
          sortable: true,
        },
        {
          label: this.$t('PDV'),
          key: 'activeOnPayBox',
          class: 'text-center',
          thStyle: { width: '100px' },
          sortable: true,
        },
        {
          label: this.$t('PDV (Contas a pagar)'),
          key: 'activeOnPayBoxAccountsPayable',
          class: 'text-center',
          thStyle: { width: '100px' },
          sortable: true,
        },
        {
          label: this.$t('PDV (Devolução)'),
          key: 'activeOnPayBoxReturn',
          class: 'text-center',
          thStyle: { width: '100px' },
          sortable: true,
        },
        {
          label: this.$t('Gera movimento'),
          key: 'generateCheckingAccountEntry',
          class: 'text-center',
          thStyle: { width: '100px' },
          sortable: true,
        },
        {
          label: this.$t('Status'),
          key: 'active',
          class: 'text-center',
          thStyle: { width: '100px' },
          sortable: true,
        },
        {
          label: this.$t('NF (Venda)'),
          key: 'activeOnInvoiceCategorySale',
          class: 'text-center',
          thStyle: { width: '100px' },
          sortable: true,
        },
        {
          label: this.$t('NF (Devol.)'),
          key: 'activeOnInvoiceCategoryReturn',
          class: 'text-center',
          thStyle: { width: '100px' },
          sortable: true,
        },
        {
          label: this.$t('NF (Transf.)'),
          key: 'activeOnInvoiceCategoryTransference',
          class: 'text-center',
          thStyle: { width: '100px' },
          sortable: true,
        },
        {
          label: this.$t('NF (Baixa Est.)'),
          key: 'activeOnInvoiceCategoryStockLosses',
          class: 'text-center',
          thStyle: { width: '100px' },
          sortable: true,
        },
        {
          label: this.$t('NF (Bonif.)'),
          key: 'activeOnInvoiceCategoryProductBonus',
          class: 'text-center',
          thStyle: { width: '100px' },
          sortable: true,
        },
        {
          label: this.$t('NF (Comod.)'),
          key: 'activeOnInvoiceCategoryStoreLending',
          class: 'text-center',
          thStyle: { width: '100px' },
          sortable: true,
        },
      ]
    },

    isEdit() {
      return !!this.formPaymentType.id
    },
  },

  mounted() {
    this.getData()
  },

  methods: {
    ...mapActions('app', { appFetchPaymentMethods: 'fetchPaymentMethods' }),
    ...mapActions('pages/settings/financial/paymentType', [
      'fetchPaymentTypes',
      'setCurrentPage',
      'setPageSize',
      'resetFilters',
    ]),
    async getData() {
      this.$nextTick(async () => {
        try {
          this.fetching = true
          await this.fetchPaymentTypes()
        } catch (error) {
          this.showError({ error })
        } finally {
          this.fetching = false
        }
      })
    },

    resetFiltersLocal() {
      this.resetFilters()
      this.getData()
    },
    filterData() {
      this.getData()
    },

    pageSizeChange(pageSize) {
      this.setPageSize(pageSize)
      this.getData()
    },

    pageChange(currentPage) {
      this.setCurrentPage(currentPage)
      this.getData()
    },

    updatePayment(row) {
      const { id } = row.item
      this.$refs.paymentTypeSidebar.onShowSidebar(id)
    },

    createPayment() {
      this.$refs.paymentTypeSidebar.onShowSidebar()
    },

    async deletePayment(row) {
      try {
        const confirm = await this.confirm()
        if (confirm) {
          await this.$http.delete(`/api/settings/financial/payment-methods/${row.id}`)
          this.getData()
          this.showSuccess({ message: this.$t('Forma de pagamento removido com sucesso') })
          await this.appFetchPaymentMethods()
        }
      } catch (error) {
        this.showError({ error })
      }
    },

    async activateDeactivatePayment(row) {
      try {
        const confirm = await this.confirm()
        if (confirm) {
          const path = row.item.active ? 'deactivate' : 'activate'
          await this.$http({
            url: `/api/settings/financial/payment-methods/${path}/${row.item.id}`,
            method: 'PUT',
          })
          this.getData()
          this.showSuccess({
            message: this.$t(`${row.item.active === true ? 'Inativado' : 'Ativado'} com sucesso`),
          })
          await this.appFetchPaymentMethods()
        }
      } catch (error) {
        this.showError({ error })
      }
    },
  },
}
</script>

<style lang="scss" scoped></style>
